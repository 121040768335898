import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";
import { urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";
import { useStoresStore } from "~/store/stores";
import { Routes } from "~/utils/routes";

interface IPromoCategory {
  id: string;
  imageName: string;
  imageUrl: string;
  name: string;
  slug: string;
}
interface IGoodsCategory {
  children: IGoodsCategory[];
  id: number;
  image?: {
    defaultSize: string;
    postfixUrl: string;
    prefixUrl: string;
  };
  name: string;
  code: string;
}
interface IBrandedGoodsCategory {
  adfoxPayload?: IYaSlotConfig | IYaSlotConfig[];
  disclaimer: string;
  goodsCategory: string;
  id: number;
  imageUrl?: string;
  publicImageUrl?: string;
  title: string;
}

export interface ICategory {
  id: string;
  icon?: string;
  preview?: string;
  name: string;
  code: string;
  key: string;
  url: string;
  parentKey: ICategory["key"] | null;
  children: ICategory[];
  yaSlotConfig?: IYaSlotConfig | IYaSlotConfig[];
  yaSlotDisclaimer?: string;
}

interface ICategoriesPreview {
  [key: string]: string;
}

interface ICategoriesStatus {
  promo: "initial" | "pending" | "success" | "error";
  goods: "initial" | "pending" | "success" | "error";
  branded: "initial" | "pending" | "success" | "error";
}

const PROMO_ROOT_KEY: ICategory["key"] = "p0000";
const GOODS_ROOT_KEY: ICategory["key"] = "g0000";

const CategoriesPreview: ICategoriesPreview = {
  [PROMO_ROOT_KEY]: "/images/catalog-header/sales.webp",
  [GOODS_ROOT_KEY]: "/images/catalog-header/all-goods.webp",
  p34: "/images/catalog-header/baby.webp",
  p32: "/images/catalog-header/decor-cosmetic.webp",
  p33: "/images/catalog-header/house-chemicals.webp",
  p122: "/images/catalog-header/hygiene.webp",
  p35: "/images/catalog-header/pets.webp",
};

export const useCategoriesStore = defineStore("categories", () => {
  const { requestShopCode, requestShopId } = storeToRefs(useStoresStore());

  const db = ref<Record<ICategory["key"], ICategory>>({});
  const structure = ref<ICategory[]>([]);
  const promo = ref<ICategory[]>([]);
  const goods = ref<ICategory[]>([]);
  const brandedGoodsCategories = ref<IBrandedGoodsCategory[]>([]);

  const status = reactive<ICategoriesStatus>({
    promo: "initial",
    goods: "initial",
    branded: "initial",
  });

  async function requestPromoCategories() {
    status.promo = "pending";

    const { data, error } = await useTransport<{
      categories: IPromoCategory[];
    }>(urls.promo.categories, {
      method: "GET",
      query: {
        storeId: requestShopId.value,
      },
    });

    if (data.value) {
      let children = data.value.categories.map((category) => {
        const key = `p${category.id}`;
        const code = category.slug;
        const url = `${Routes.PromoCatalog}/${category.id}-${code}?shopCode=${requestShopCode.value}`;
        const preview =
          key in CategoriesPreview ? CategoriesPreview[key] : undefined;

        const format: ICategory = {
          key,
          id: category.id,
          name: category.name,
          code,
          icon: category.imageUrl,
          preview,
          url,
          parentKey: PROMO_ROOT_KEY,
          children: [],
        };

        db.value[key] = format;

        return format;
      });

      children = children.filter((category) => category.id !== "2");

      const root: ICategory = {
        id: "",
        key: PROMO_ROOT_KEY,
        name: "Акции и скидки",
        code: "root",
        icon: "/images/promo-catalog-preview.png",
        preview:
          PROMO_ROOT_KEY in CategoriesPreview
            ? CategoriesPreview[PROMO_ROOT_KEY]
            : undefined,
        url: Routes.PromoCatalog,
        parentKey: null,
        children,
      };
      structure.value = [root, ...structure.value];
      promo.value = [root];
      db.value[PROMO_ROOT_KEY] = root;
      status.promo = "success";
    }

    if (error.value) {
      status.promo = "error";
    }
  }

  function formatGoodsCategories(
    category: IGoodsCategory,
    parentKey: ICategory["parentKey"] = null,
  ): ICategory {
    const key = `g${category.id}`;
    const format: ICategory = {
      key,
      parentKey,
      id: String(category.id),
      name: category.name,
      code: category.code,
      icon:
        category.image && parentKey === GOODS_ROOT_KEY
          ? `${category.image.prefixUrl}${category.image.defaultSize}${category.image.postfixUrl}`
          : undefined,
      preview: key in CategoriesPreview ? CategoriesPreview[key] : undefined,
      url: `${Routes.Catalog}/${category.id}-${category.code}?shopCode=${requestShopCode.value}`,
      children: category.children.map((subcategory) =>
        formatGoodsCategories(subcategory, key),
      ),
    };

    db.value[key] = format;

    return format;
  }

  async function requestGoodsCategories() {
    status.goods = "pending";

    const { data, error } = await useTransport<IGoodsCategory[]>(
      urls.goods.categories,
      {
        method: "GET",
        query: {
          storeCode: requestShopCode.value,
        },
      },
    );

    if (data.value) {
      let categories: ICategory[] = data.value.map((category) =>
        formatGoodsCategories(category, GOODS_ROOT_KEY),
      );

      categories = categories.filter((category) => category.id !== "1");

      const root = {
        id: "",
        key: GOODS_ROOT_KEY,
        name: "Все категории",
        code: "root",
        preview:
          GOODS_ROOT_KEY in CategoriesPreview
            ? CategoriesPreview[GOODS_ROOT_KEY]
            : undefined,
        url: `${Routes.Catalog}?shopCode=${requestShopCode.value}`,
        parentKey: null,
        children: categories,
      };

      structure.value = [...structure.value, ...categories];
      goods.value = [root];
      db.value[GOODS_ROOT_KEY] = root;
      status.goods = "success";
      setBrandedDB();
    }

    if (error.value) {
      status.goods = "error";
    }
  }

  async function requestBrandedGoodsCategories() {
    status.branded = "pending";

    const { data, status: statusRes } = await useTransport<
      IBrandedGoodsCategory[]
    >(urls.content.brandedGoodsCategories, {
      method: "GET",
    });

    if (statusRes.value === "success") {
      status.branded = "success";
      brandedGoodsCategories.value = data.value || [];
      setBrandedDB();
    } else {
      status.branded = "error";
    }
  }

  const requestCategories = async () => {
    structure.value = [];
    await Promise.all([requestPromoCategories(), requestGoodsCategories()]);
  };

  const getKeyById = (id: ICategory["id"], type: "promo" | "goods"): string => {
    if (!id) {
      id = "0000";
    }
    return `${type === "promo" ? "p" : "g"}${id}`;
  };

  const getCategoryByKey = (key: ICategory["key"]): ICategory | null => {
    return db.value[key] || null;
  };

  const getCategoryById = (
    id: ICategory["id"],
    type: "promo" | "goods",
  ): ICategory | null => {
    const key = getKeyById(id, type);
    return getCategoryByKey(key);
  };

  const getParents = (key: ICategory["key"]): ICategory[] => {
    let result: ICategory[] = [];

    const current = getCategoryByKey(key);

    if (current) {
      result = [current];

      if (current.parentKey) {
        const parents = getParents(current.parentKey);

        if (parents.length) {
          result = [...parents, ...result];
        }
      }
    }

    return result;
  };

  const getParentsByKey = (key: ICategory["key"]): ICategory[] => {
    const current = getCategoryByKey(key);
    if (!current || !current.parentKey) {
      return [];
    }

    const parent = getCategoryByKey(current.parentKey);

    if (!parent) {
      return [];
    }

    return getParents(parent.key);
  };

  const getParentsById = (
    id: ICategory["id"],
    type: "promo" | "goods",
  ): ICategory[] => {
    const key = getKeyById(id, type);

    return getParentsByKey(key);
  };

  const getChildrenByKey = (key: ICategory["key"]): ICategory[] => {
    const current = getCategoryByKey(key);
    let result: ICategory[] = [];

    if (current) {
      result = [current];

      if (current.children) {
        const childs = current.children.reduce<ICategory[]>((res, child) => {
          const next = getChildrenByKey(child.key);
          return [...res, ...next];
        }, []);

        if (childs.length) {
          result = [...result, ...childs];
        }
      }
    }

    return result;
  };

  const getChildrenById = (
    id: ICategory["id"],
    type: "promo" | "goods",
  ): ICategory[] => {
    const key = getKeyById(id, type);

    return getChildrenByKey(key);
  };

  const getTypeByKey = (key: ICategory["key"]): "promo" | "goods" => {
    return key[0] === "p" ? "promo" : "goods";
  };

  const getTreeNameByKey = (key: ICategory["key"]): string => {
    const current = getCategoryByKey(key);
    if (!current) {
      return "";
    }
    const arrayCategoryNames = getParentsByKey(current.key).map((i) => i.name);
    arrayCategoryNames.push(current.name);

    return arrayCategoryNames.join("/");
  };

  const setBrandedDB = () => {
    brandedGoodsCategories.value.forEach((i) => {
      if (db.value[`g${i.goodsCategory}`]) {
        db.value[`g${i.goodsCategory}`].preview = i.publicImageUrl;
        db.value[`g${i.goodsCategory}`].yaSlotConfig = i.adfoxPayload;
        db.value[`g${i.goodsCategory}`].yaSlotDisclaimer = i.disclaimer;
      }
    });
  };

  return {
    requestCategories,
    getCategoryByKey,
    getCategoryById,
    getParentsByKey,
    getParentsById,
    getChildrenByKey,
    getChildrenById,
    getTypeByKey,
    getTreeNameByKey,
    db,
    structure,
    promo,
    goods,
    status,
    requestBrandedGoodsCategories,
  };
});
