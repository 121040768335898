import { type ILoggerPlugin, LOGGER_PREFIXES } from "~/typings/plugins/logger";

let logger: ILoggerPlugin | null = null;

export const initLogger = ($logger: ILoggerPlugin) => {
  logger = $logger;
};

const getLogFunction = (prefix: LOGGER_PREFIXES, type = "log") => {
  if (!logger)
    return (...toLog: any[]) => {
      if (import.meta.dev) {
        // eslint-disable-next-line no-console
        console.log("Logger not initialized", toLog);
      }
    };
  return logger.get(prefix, type);
};

export const logComponent = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Component)(...toLog);
export const logError = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Error, "error")(...toLog);
export const logComponentError = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.ComponentError, "error")(...toLog);
export const logRequest = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Request)(...toLog);
export const logRequestError = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.RequestError, "error")(...toLog);
export const logPubSub = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.PubSub)(...toLog);
export const logReplacement = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Replacement)(...toLog);
export const logCache = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Cache)(...toLog);
export const logStatistics = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Statistics)(...toLog);
export const logProfiling = (...toLog: any[]) =>
  getLogFunction(LOGGER_PREFIXES.Profiling, "table")(...toLog);
