import { urls } from "~/api/config";
import type {
  IGeohelperStore,
  IGeohelperStoreQuery,
} from "~/typings/api/geohelper";

export default () => {
  const fetchStore = (query: Partial<IGeohelperStoreQuery> = {}) => {
    const headers = useRequestHeaders();
    const { url, config } = apiGetWebFetchConfig(
      urls.geohelper.store,
      headers,
      { query },
    );
    return $fetch<IGeohelperStore>(url, config);
  };

  return {
    fetchStore,
  };
};
