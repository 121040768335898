import { urls } from "~/api/config";
import type {
  IStoreSearchAddressBody,
  IStoreSearchAddressResponse,
  IStoreSearchGeoBody,
  IStoreSearchGeoResponse,
  IStoreSearchStoreResponse,
} from "~/typings/api/storeSearch";

export default () => {
  const fetchAddressSearch = (body: Partial<IStoreSearchAddressBody> = {}) => {
    const headers = useRequestHeaders();
    const { url, config } = apiGetWebFetchConfig(
      urls["store-search"].address,
      headers,
      { body, method: "POST" },
    );
    return $fetch<IStoreSearchAddressResponse>(url, config);
  };
  const fetchGeoSearch = (body: Partial<IStoreSearchGeoBody> = {}) => {
    const headers = useRequestHeaders();
    const { url, config } = apiGetWebFetchConfig(
      urls["store-search"].geo,
      headers,
      { body, method: "POST" },
    );
    return $fetch<IStoreSearchGeoResponse>(url, config);
  };
  const fetchStoreByCode = async (code: string) => {
    const headers = useRequestHeaders();
    const { url, config } = apiGetWebFetchConfig(
      `${urls["store-search"].store}/${code}`,
      headers,
    );
    const data = await $fetch<IStoreSearchStoreResponse>(url, config);
    const store = data.store;
    const [latitude, longitude] = [store.longitude, store.latitude];
    return {
      ...data,
      store: {
        ...data.store,
        latitude,
        longitude,
      },
    };
  };

  return {
    fetchAddressSearch,
    fetchGeoSearch,
    fetchStoreByCode,
  };
};
